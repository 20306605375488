/** @flow */

import {BulletDropdown} from '@codistica/react';
import {
    Instagram,
    Facebook,
    Twitter,
    Youtube,
    Linkedin
} from '@codistica/react-icons';
import React from 'react';
import styles from './app.module.scss';
// import oberkeyLogo from './img/oberkey-logo-mini-white.svg';

/**
 * @description App main component.
 * @returns {React.Component} App main component.
 */
function App() {
    const socialIconsHeight = 30;

    return (
        <div className={styles.app}>
            <header className={styles.appHeader}>
                {/*<img*/}
                {/*    src={oberkeyLogo}*/}
                {/*    alt='oberkey-logo'*/}
                {/*    className={styles.oberkeyLogo}*/}
                {/*/>*/}
                <div className={styles.titles}>
                    <span className={styles.mainTitle}>A CALL FOR CHANGE</span>
                    <br />
                    <span className={styles.secondaryTitle}>
                        REFLECT YOURSELF
                    </span>
                    <br />
                    <span className={styles.tertiaryTitle}>
                        We are coming very soon...
                    </span>
                </div>
                <div className={styles.socialBar}>
                    <Instagram
                        height={socialIconsHeight}
                        href={'https://www.instagram.com/oberkey'}
                    />
                    <Facebook
                        height={socialIconsHeight}
                        href={'https://www.facebook.com/oberkey'}
                    />
                    <Twitter
                        height={socialIconsHeight}
                        href={'https://twitter.com/oberkeyofficial'}
                    />
                    <Youtube height={socialIconsHeight} />
                    <Linkedin height={socialIconsHeight} />
                </div>
                <BulletDropdown
                    title={'Contact Us'}
                    items={{
                        'Send Email': 'mailto:contact@oberkey.com'
                    }}
                    autoSpacing={'top'}
                    style={{marginTop: 30}}
                    className={styles.contactUs}
                    customClassNames={{
                        item: {
                            color: '#111111'
                        }
                    }}
                    autoClose={true}
                />
            </header>
        </div>
    );
}

export {App};
