/** @flow */

import React from 'react';
import ReactDOM from 'react-dom';
import {App} from './app.js';
import * as serviceWorker from './service-worker.js';
import './fonts/lato-codistica/lato-codistica.css';
import './index.module.scss';

// TODO: CREATE OPTIMIZED FONTS REPOSITORY.
// TODO: INVESTIGATE FONT LOADERS. (CURRENT LOADER (IF ANY) SEEMS TO BE LOADING FONT FILES FROM CSS @font-face SRC URLs. BUT THERE ARE LOADERS THAT LOADS DIRECTLY THROUGH import IN CODE (SEE: https://material-ui.com/customization/typography/#self-hosted-fonts))
// TODO: LOADING FROM @font-face URLS SHOULD BE THE BEST METHOD BECAUSE ONLY THE BROWSER CAN CHOOSE ITS PREFERRED FORMAT.
// TODO: HOWEVER. LOADING FONT FILE DIRECTLY IN CODE MAY FACILITATE THINGS WITH Scriptfiber. BUT HAVING ONLY ONE (OR TWO) FORMAT.
// TODO: CONSIDER RESULTS FOR Scriptfiber.

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
